export const contactsData = {
	email: "lalitrajput201@gmail.com",
	phone: "+917972306084",
	address: "Dhule, Maharashtra, India",
	github: "https://github.com/L-A-L-I-T",
	facebook: "",
	linkedIn: "https://www.linkedin.com/in/lalit-rajput/",
	twitter: "https://twitter.com/lalitrajput2001",
	medium: "",
	stackOverflow: "",
	devUsername: "",
};
